import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import "@/icons";
import "@/styles/index.scss"
import "@/assets/fonts/ali-font.css";

Vue.config.productionTip = false


import { t } from "@/utils/translate";
Vue.prototype.$t = t;
// 全局引入element
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "element-ui/lib/theme-chalk/display.css";
Vue.use(ElementUI);
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
