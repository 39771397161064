import Vue from "vue"; //引入Vue
import Router from "vue-router"; //引入vue-router
Vue.use(Router); //Vue全局使用Router
export default new Router({
  mode: "history",
  routes: [
    {
      path: "/404",
      component: () => import("@/views/404"),
      hidden: true,
    },
    {
      path: "/",
      redirect: "/home",
      component: () => import("@/views/home/index"),
      children: [
        {
          path: "home",
          name: "home",
          component: () => import("@/views/home/index"),
        },
      ],
    },
    {
      path: "/courtesy",
      name: "courtesy",
      component: () => import("@/views/form/courtesy"),
    },
  ],
});
